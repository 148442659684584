import { getWorkspaceDocuments } from "@/http/api/workspaces";
import type { LoaderFunctionArgs } from "react-router-dom";
import { getFilteredData } from "@/helpers";

export default async function questionnaireLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const workspaceId = params.workspaceId;
  if (!workspaceId) {
    return null;
  }
  const response = await getWorkspaceDocuments(workspaceId, {
    signal: request.signal,
  });
  // Filter out reports from the response
  return getFilteredData({ data: response.documents, route: "documents" });
}

export const INTEGRATION_POPUP_DELAY = 10_000; // 10 seconds

export const EXCEL_MIME = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const EXCEL_EXTENSIONS = [".xls", ".xlsx"];

export const ACCEPTED_FILE_EXTENSIONS = [".pdf", ".doc", ".docx"];
export const ACCEPTED_MIME = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];
export const INPUT_FILE_ACCEPT = [
  ...ACCEPTED_FILE_EXTENSIONS,
  ...ACCEPTED_MIME,
].join(",");

export const ERRORS = {
  NO_WORKSPACE: "No workspace found",
};

export const SOURCE_CATEGORIES = [
  "General Knowledge",
  "Previous RFPs",
  "Marketing Materials",
  "Legal Documents",
  "Reports",
  "Financial Data",
  "News Articles",
  "Regulatory Updates",
  "Previous DDQs",
];

export const MARKET_NEWSFLASH_TEMPLATE_SLUG = "newsflash";
export const REGULATORY_UPDATES_TEMPLATE_SLUG = "reg_update";
export const RFP_TEMPLATE_SLUG = "rfp";

export const REGULAR_CHATBOT_SLUG = "regular";
export const A_TO_B_CHATBOT_SLUG = "a-to-b";

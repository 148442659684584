import { Box, Group, Image, Stack, Text, Title } from "@mantine/core";
import type { PropsWithChildren } from "react";

export default function RouteErrorCard({
  heading,
  subheading,
  message,
  children,
  image,
}: RouteErrorCardType) {
  return (
    <>
      <Group gap="xs">
        <Stack gap="xl" maw={320}>
          <Stack gap="xs">
            <Title order={1} c="lochmara.6" fw={700} fz={32}>
              {heading}
            </Title>
            <Title order={2} c="neutral.10" fw={500} fz={20}>
              {subheading}
            </Title>
          </Stack>
          <Text c="neutral.8" fw={500} fz={16}>
            {message}
          </Text>
          {children}
        </Stack>
        <Box maw={500} mah={364}>
          <Image src={image} alt={heading} fit="contain" />
        </Box>
      </Group>
    </>
  );
}

interface RouteErrorCardType extends PropsWithChildren {
  heading: string;
  subheading: string;
  message: string;
  image: string;
}

import { throwErrorResponse } from "@/helpers";
import { getWorkspaceSources } from "@/http/api/workspaces";
import type { LoaderFunctionArgs } from "react-router-dom";

export default async function resourcesLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  if (!params.workspaceId) {
    return throwErrorResponse({
      status: 404,
      statusText: "Workspace Not found",
    });
  }

  const response = await getWorkspaceSources(params.workspaceId, {
    signal: request.signal,
  });
  return response.sources;
}

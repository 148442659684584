import { getWorkspaceDocuments } from "@/http/api/workspaces";
import type { LoaderFunctionArgs } from "react-router-dom";
import { getTemplates } from "@/http/api/templates";
import type { DocumentType } from "@/http/api/document/types";
import { getFilteredData, hasUserAccessToNewsflash } from "@/helpers";

export default async function reportsLoader({
  request,
  params,
}: LoaderFunctionArgs): Promise<ReportLoaderResponseType> {
  const workspaceId = params.workspaceId;
  if (!workspaceId) {
    throw new Error("Workspace ID is required");
  }
  const response = await getWorkspaceDocuments(workspaceId, {
    signal: request.signal,
  });

  // Filter out non-reports from the response
  const reports = getFilteredData({
    data: response.documents,
    route: "reports",
  });

  const templates = await getTemplates();

  return {
    reports,
    hasUserAccessToNewsflash: hasUserAccessToNewsflash(templates.templates),
  };
}

export interface ReportLoaderResponseType {
  reports: DocumentType[];
  hasUserAccessToNewsflash: boolean;
}

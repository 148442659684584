import { atom, getDefaultStore } from "jotai";
import { atomWithStorage, createJSONStorage, atomWithLazy } from "jotai/utils";
import AuthClient from "./http/authClient";
import type { WorkspaceTypeLite } from "./http/api/workspaces/types";

const defaultStore = getDefaultStore();

const sideMenuStorage = createJSONStorage<boolean>(() => sessionStorage);
export const sideMenuAtom = atomWithStorage<boolean>(
  "showSideMenu",
  true,
  sideMenuStorage,
);

const defaultUserState = () => {
  const { user_id, company_id, email, is_admin } = AuthClient.getDecodedToken();
  return {
    id: user_id,
    email,
    companyId: company_id,
    isAdmin: is_admin,
  };
};
const userAtom = atomWithLazy<UserAtomType>(defaultUserState);

export const isCompanyAdminAtom = atom(
  (get) => get(userAtom).isAdmin,
  (get, set, isAdmin: boolean) => {
    const user = get(userAtom);
    set(userAtom, { ...user, isAdmin });
  },
);
export const setIsCompanyAdmin = (isAdmin: boolean) => {
  defaultStore.set(isCompanyAdminAtom, isAdmin);
};

export const userWorkspacesAtom = atom<WorkspaceTypeLite[]>([]);
export const setUserWorkspaces = (userWorkspaces: WorkspaceTypeLite[]) => {
  defaultStore.set(userWorkspacesAtom, userWorkspaces);
};

interface UserAtomType {
  id: string;
  email: string;
  companyId: string;
  isAdmin: boolean;
}

import { throwErrorResponse } from "@/helpers";
import AuthClient from "@/http/authClient";

export default function adminLoader() {
  const { email } = AuthClient.getDecodedToken();
  const [, domain] = email.split("@");
  if (domain !== "kodex-ai.com") {
    return throwErrorResponse({ status: 404, statusText: "Not found" });
  }
  return null;
}

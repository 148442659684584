import { getMe } from "@/http/api/users";
import { setIsCompanyAdmin, setUserWorkspaces } from "@/atoms";
import type { LoaderFunctionArgs } from "react-router-dom";

export default async function appLoader({ request }: LoaderFunctionArgs) {
  const requestURL = new URL(request.url);
  if (requestURL.pathname.includes("/admin")) {
    return null;
  }
  const me = await getMe({ signal: request.signal });
  setIsCompanyAdmin(me.is_company_admin);
  const sortedWorkspaces = me.workspace_memberships.sort((a, b) =>
    a.created_at.localeCompare(b.created_at),
  );
  setUserWorkspaces(sortedWorkspaces);
  return sortedWorkspaces;
}

import { getDocument } from "@/http/api/document";
import type { LoaderFunctionArgs } from "react-router-dom";
import { setQuestionnairesConversations } from "@/components/Editor/editorAtoms";

export default async function questionnaireDetailsLoader({
  request,
  params,
}: LoaderFunctionArgs) {
  const { id } = params;
  if (!id) {
    return null;
  }
  const questionnaire = await getDocument(id, {
    signal: request.signal,
  });

  setQuestionnairesConversations({
    [id]: questionnaire.conversation_id,
  });
  return questionnaire;
}

import ky from "ky";
import { type Options } from "ky";
import AuthClient from "./authClient";

const appURL = import.meta.env.VITE_APP_URL;

const DEFAULT_KY_CONFIG: Options = {
  prefixUrl: appURL,
  timeout: false,
  hooks: {
    beforeError: [
      async (error) => {
        if (error.response.status === 401) {
          await AuthClient.logout();
          window.location.reload();
        }
        return Promise.reject(error);
      },
    ],
  },
};

class BaseHTTPClient {
  protected _ky: typeof ky;

  constructor() {
    this._ky = ky.create(DEFAULT_KY_CONFIG);
  }

  get<T>(endpoint: string, options?: Options): Promise<T> {
    return this._ky.get(endpoint, options).json();
  }

  post<T>(endpoint: string, options?: Options): Promise<T> {
    return this._ky.post(endpoint, options).json();
  }

  put<T>(endpoint: string, options?: Options): Promise<T> {
    return this._ky.put(endpoint, options).json();
  }

  delete<T>(endpoint: string, options?: Options): Promise<T> {
    return this._ky.delete(endpoint, options).json();
  }
}

export const baseHttp = new BaseHTTPClient();

export default BaseHTTPClient;

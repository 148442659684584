import { createTheme, rem, type CSSVariablesResolver } from "@mantine/core";

export const theme = createTheme({
  fontFamily: "DM Sans Variable, sans-serif;",
  defaultRadius: "sm",
  headings: {
    fontWeight: "500",
    sizes: {
      h1: { fontSize: rem(24), lineHeight: "1.4" },
      h2: { fontSize: rem(20), lineHeight: "1.2" },
    },
  },
  primaryColor: "lochmara",
  colors: {
    lochmara: [
      "#f2f8fd",
      "#e4effa",
      "#c2dff5",
      "#8cc4ed",
      "#4fa5e1",
      "#288acf",
      "#1b75bc",
      "#16588e",
      "#164b76",
      "#184062",
      "#102841",
    ],
    neutral: [
      "#f4f7fd",
      "#e9effb",
      "#dae6f5",
      "#cbd8e7",
      "#afbed0",
      "#92a3ba",
      "#7b8fa8",
      "#647c97",
      "#576d86",
      "#465a6f",
      "#384759",
      "#253241",
      "#1a2634",
    ],
  },
  breakpoints: {
    xs: "640px",
    sm: "768px",
    md: "1024px",
    lg: "1280px",
    xl: "1536px",
  },
});

export const resolver: CSSVariablesResolver = (theme) => ({
  variables: {
    "--mantine-body-background": theme.colors.neutral[0],
  },
  light: {
    "--mantine-body-background": theme.colors.neutral[0],
  },
  dark: {
    "--mantine-body-background": theme.colors.neutral[9],
  },
});

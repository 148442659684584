import loadable from "@loadable/component";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { ERROR_NO_TOKEN, ERROR_NO_WORKSPACE } from "@/http/constants";
import { HTTPError } from "ky";
import DefaultErrorPage from "./DefaultErrorPage";
import RedirectToLogin from "../RedirectToLogin";
import RouteErrorLayout from "@/layouts/RouteErrorLayout";

const Error404 = loadable(() => import("./404"));
const Error500 = loadable(() => import("./500"));

export default function RouteError() {
  return (
    <RouteErrorLayout>
      <ErrorMapper />
    </RouteErrorLayout>
  );
}

function ErrorMapper() {
  const error = useRouteError();
  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />;
    }
    if (error.status === 500) {
      return <Error500 />;
    }
  }
  if (error instanceof HTTPError) {
    if (error.response.status === 404) {
      return <Error404 />;
    }
    if (error.response.status === 500) {
      return <Error500 />;
    }
  }
  if (error instanceof Error) {
    if (error.message === ERROR_NO_TOKEN) {
      return <RedirectToLogin />;
    }
    if (error.message === ERROR_NO_WORKSPACE) {
      return <Error404 />;
    }
  }
  return <DefaultErrorPage />;
}
